<template>
  <v-container>
    <v-row align="center" justify="space-around">
      <v-card min-width="460">
        <v-card-title>Change Password</v-card-title>
        <v-card-text>
          <v-form v-model="form.valid" ref="form" lazy-validation>
            <v-text-field :value="getUserName" disabled outlined>
            </v-text-field>
            <v-text-field
              label="Old Password"
              v-model="form.oldPassword"
              @click:append="form.showOldPassword = !form.showOldPassword"
              :append-icon="form.showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="form.showOldPassword ? 'text' : 'password'"
              required
              :rules="form.rules.oldPassword"
              outlined
              :color="colorTheme.textField"
              tabindex="1"
              @keyup.enter="changePassword"
            ></v-text-field>
            <v-text-field
              label="New Password"
              v-model="form.newPassword"
              @click:append="form.showNewPassword = !form.showNewPassword"
              :append-icon="form.showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="form.showNewPassword ? 'text' : 'password'"
              required
              :rules="form.rules.newPassword"
              outlined
              :color="colorTheme.textField"
              tabindex="2"
              @keyup.enter="changePassword"
            ></v-text-field>
            <v-text-field
              label="Confirm Password"
              v-model="form.confirmNewPassword"
              @click:append="
                form.showConfirmNewPassword = !form.showConfirmNewPassword
              "
              :append-icon="
                form.showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'
              "
              :type="form.showConfirmNewPassword ? 'text' : 'password'"
              required
              :rules="confirmNewPasswordRules"
              outlined
              :color="colorTheme.textField"
              tabindex="3"
              @keyup.enter="changePassword"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="changePassword"
            :disabled="!form.valid"
            class="py-4 px-10 white--text"
            tile
            large
            min-width="100"
            :color="colorTheme.button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <custom-error
      ref="showCustomError"
      :ActionError.sync="ActionError"
      :ActionErrorMessage.sync="ActionErrorMessage"
      card-width="460"
      error-title="Change Password Error"
    ></custom-error>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapState } from "vuex";
import CustomError from "../components/fee-schedule/CustomError.vue";
import amplitude from 'amplitude-js'

export default {
  components: {
    CustomError,
  },
  data: () => ({
    form: {
      valid: true,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      rules: {
        oldPassword: [(p) => !!p || "Old Password is required"],
        newPassword: [
          (p) => !!p || "New Password is required",
          (p) =>
            (p && p.length >= 8) ||
            "Password must be at least 8 characters long",
          (p) =>
            /[a-z]/.test(p) || "New Password must contain lowercase letters",
          (p) =>
            /[A-Z]/.test(p) || "New Password must contain uppercase letters",
          (p) => /[0-9]/.test(p) || "New Password must contain numbers",
          (p) =>
            /[^A-Za-z0-9]/.test(p) ||
            "New Password must contain special characters",
        ],
      },
    },
    ActionError: false,
    ActionErrorMessage: "",
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    getUserName() {
      return this.$cookies.get("username");
    },
    confirmNewPasswordRules() {
      return [
        (np) => !!np || "This field is required",
        () =>
          this.form.confirmNewPassword === this.form.newPassword ||
          "Passwords do not match",
      ];
    },
  },
  methods: {
    async changePassword() {
      if (this.$refs.form.validate()) {
        const { oldPassword, newPassword } = this.form;
        Auth.currentAuthenticatedUser()
          .then((user) => {
            if (user.username != this.$cookies.get("username")) {
              throw "Wrong user!";
            } else if (oldPassword == newPassword) {
              throw "Old and new password must be different!";
            }
            amplitude.getInstance().logEvent("Profile [Password Change]", {
              "Status": 'Success'
            });
            return Auth.changePassword(user, oldPassword, newPassword);
          })
          .then(() => {
            this.$router.replace("/profile");
          })
          .catch((err) => {
            amplitude.getInstance().logEvent("Profile [Password Change]", {
              "Status": 'Fail'
            });
            var errMessage = "";
            if (
              err.message !== undefined &&
              err.message !== null &&
              err.message !== ""
            ) {
              errMessage = err.message;
            } else {
              errMessage = err;
            }
            this.refreshCustomError(errMessage);
          });
      }
    },
    refreshCustomError(error) {
      this.$refs.showCustomError.refresh(error);
    },
  },
};
</script>
